import DivesRepository from "./DivesRepository";
import UsersRepository from "./UsersRepository";
import RolesRepository from "./RolesRepository";
import OperatorRepository from "./OperatorRepository";
import SubscriptionsRepository from "./SubscriptionsRepository"

const repositories = {
  users: UsersRepository,
  dives: DivesRepository,
  roles: RolesRepository,
  operator: OperatorRepository,
  subscriptions: SubscriptionsRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};