import { defineStore } from "pinia";

import useBackendFetch from "@/repositories/BackendFetch";

import { useStorage } from "@vueuse/core";
import { useToast } from "vue-toastification";
const toast = useToast();
const BREAKPOINT = 1280;

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useSettingsStore = defineStore("settings", {
  state: () => {
    const settings =
      localStorage.getItem("user_settings") &&
      localStorage.getItem("user_settings") != "undefined"
        ? JSON.parse(localStorage.getItem("user_settings"))
        : { dark_mode: true, language: "it" };

    const isMobile = false;
    return {
      settings,
      isMobile,
    };
  },
  actions: {
    async get() {
      const { data } = await useBackendFetch("settings").get().json();
      this.settings = data.value;
      localStorage.setItem("user_settings", JSON.stringify(data.value));
    },

    async update(values) {
      const toast = useToast();

      const { data } = await useBackendFetch("settings").post(values).json();
      this.settings = data.value;
      localStorage.setItem("user_settings", JSON.stringify(data.value));
      toast.success("Settings successfully saved");
    },
    checkPlaformWidth(route) {
      this.platformWidth = window.innerWidth;
      //isPlatform("mobile") ||
      if (this.platformWidth < BREAKPOINT) {
        this.isMobile = true;
        //if (route.path.startsWith("/web")) router.replace("/mobile");
      } else {
        this.isMobile = false;
        // if (!route.path.startsWith("/web")) router.replace("/web");
      }
      return this.isMobile;
    },
  },

  getters: {
    // automatically infers the return type as a number
  },
});
