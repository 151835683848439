import axios from 'axios';
import router  from '@/router'

import { useUserStore } from '@/store/user'
import { useToast } from 'vue-toastification'

// You can use your own logic to set your local or production domain
const baseDomain = process.env.VUE_APP_BACKEND_URL;
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}/api`;

const instance = axios.create({
  baseURL,
});
instance.interceptors.response.use(null,async function(error) {
  if (error.response.status === 401) {
    const userStore = useUserStore();
    await userStore.resetUser();
    const  toast= useToast();
    toast.error("You are not authorized to perform this action")
  }
  else if( error.response.status === 403){
    const  toast= useToast();
    toast.error("You are not authorized to perform this action")
    router.push('/dashboard')
  }
  return Promise.reject(error);
});
export default instance;
