import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import { useUserStore } from "@/store/user";
import { useSettingsStore } from "@/store/settings.js";

import { useToast } from "vue-toastification";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import("../views/HomePage.vue"),
    name: "Home",
    meta: {
      public: true,
      onlyWhenActiveSubscription: false,
    },
  },
  {
    path: "/privacy",
    component: () => import("../views/Privacy.vue"),
    name: "Privacy",
    meta: {
      public: true,
      onlyWhenActiveSubscription: false,
    },
  },

  {
    path: "/dashboard",
    component: () => import("../views/Dashboard.vue"),
    name: "Dashboard",
    meta: {
      public: false,
      onlyWhenActiveSubscription: false,
    },
  },
  {
    path: "/subscriptions",
    component: () => import("../views/Subscriptions.vue"),
    name: "Subscriptions",
    meta: {
      public: false,
      onlyWhenActiveSubscription: false,
    },
  },
  {
    path: "/profile",
    component: () => import("../views/Profile.vue"),
    name: "Profile",
    meta: {
      public: false,
      onlyWhenActiveSubscription: false,
    },
  },
  {
    path: "/profile/anamnesis",
    component: () => import("../views/AnamnesisForm.vue"),
    name: "Anamnesis",
    meta: {
      public: false,
      onlyWhenActiveSubscription: false,
    },
  },

  {
    path: "/logbook/dive/:id/",
    component: () => import("../views/Dive.vue"),
    name: "Dive",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
    },
  },
  {
    path: "/logbook/:id?",
    component: () => import("../views/Logbook.vue"),
    name: "Logbook",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
    },
  },
  {
    path: "/wearable/live",
    component: () => import("../views/LiveData.vue"),
    name: "LiveData",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
      requiredSubscriptions: ["advanced"],
    },
  },
  {
    path: "/wearable/sensor/:sensor",
    component: () => import("../views/WearableDay.vue"),
    name: "WearableSensor",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
      requiredSubscriptions: ["advanced"],
    },
  },
  {
    path: "/wearable",
    component: () => import("../views/Wearable.vue"),
    name: "Wearable",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
      requiredSubscriptions: ["advanced"],
    },
  },
  {
    path: "/statistics",
    component: () => import("../views/StatisticsPage.vue"),
    name: "Statistics",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
    },
  },
  {
    path: "/training",
    component: () => import("../views/TrainingPage.vue"),
    name: "Training",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
      requiredSubscriptions: ["advanced", "fitness"],
    },
  },
  {
    path: "/doppler/evaluate",
    component: () => import("../views/DopplerEvaluate.vue"),
    name: "DopplerEvaluate",
    meta: {
      public: false,
      permissions: ["evaluate-doppler"],
    },
  },
  {
    path: "/training/session/:id",
    component: () => import("../views/TrainingSessionPage.vue"),
    name: "TrainingSession",
    meta: {
      public: false,
      onlyWhenActiveSubscription: true,
      requiredSubscriptions: ["advanced", "fitness"],
    },
  },
  {
    path: "/roles/users",
    component: () => import("../views/UsersRoles.vue"),
    name: "UsersRoles",
    meta: {
      public: false,
      permissions: ["edit_users_roles"],
      //roles:['admin']
    },
  },
  {
    path: "/divers",
    component: () => import("../views/UsersPage.vue"),
    name: "Users",
    meta: {
      public: false,
      permissions: ["view_all_users"],
      //roles:['admin']
    },
  },

  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    name: "Login",
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/signup",
    component: () => import("../views/Signup.vue"),
    name: "Signup",
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/forgot-password",
    component: () => import("../views/ForgotPassword.vue"),
    name: "ForgotPassword",
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/reset-password/:token",
    component: () => import("../views/ResetPassword.vue"),
    name: "ResetPassword",
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const settingsStore = useSettingsStore();

  const toast = useToast();
  settingsStore.checkPlaformWidth(to);

  const isPublic = to.matched.some((record) => record.meta.public);
  const requiresActiveSubscription = to.matched.some(
    (record) => record.meta.onlyWhenActiveSubscription
  );
  let authPermission;
  let reqSubscriptions;
  if (to.meta.permissions) {
    authPermission = to.meta.permissions;
  } else authPermission = false;
  if (to.meta.requiredSubscriptions)
    reqSubscriptions = to.meta.requiredSubscriptions;
  else reqSubscriptions = false;

  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );

  if (!isPublic && authPermission && !userStore.isAbleTo(authPermission)) {
    toast.error("You are to authorized to perform this action");
    return next({
      path: "/dashboard",
    });
  }
  //if (to.meta.roles &)
  if (requiresActiveSubscription && !userStore.hasActiveSubscription()) {
    toast.error("You are to authorized to perform this action");
    return next({
      path: "/dashboard",
    });
  }
  if (reqSubscriptions && !userStore.hasSubscriptions(reqSubscriptions)) {
    toast.error("You are to authorized to perform this action");
    return next({
      path: "/dashboard",
    });
  }
  if (!isPublic && !userStore.loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }

  if (userStore.loggedIn && onlyWhenLoggedOut) {
    return next("/dashboard");
  }

  next();
});

export default router;
