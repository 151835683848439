import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!

  return (_openBlock(), _createBlock(_component_IonSplitPane, {
    disabled: _ctx.splitPaneActive,
    when: "false",
    "content-id": "main-content"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_side_menu, { splitPane: _ctx.splitPaneActive }, null, 8, ["splitPane"]),
      _createVNode(_component_ion_router_outlet, { id: "main-content" })
    ]),
    _: 1
  }, 8, ["disabled"]))
}