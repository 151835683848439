
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonBackButton,
  IonPage,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { useI18n } from "vue-i18n";
import BaseBanner from "@/components/base/BaseBanner.vue";
export default defineComponent({
  name: "BasePage",
  props: {
    title: String,
    defaultUrl: {
      type: String,
      default: "/",
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonBackButton,
    IonPage,
    IonToolbar,
    BaseBanner,
  },
  setup() {
    const { t } = useI18n({});

    return {
      t,
    };
  },
});
