<template>
  <IonApp>
    <loader />
    <public v-if="publicPage"></public>
    <private v-else></private>
  </IonApp>
</template>

<script>
import Private from "@/components/layout/Private.vue";
import Public from "@/components/layout/Public.vue";

import Loader from "@/components/base/Loader.vue";

import { defineComponent, computed, watchEffect } from "vue";

import { IonApp } from "@ionic/vue";
import { useI18n } from "vue-i18n";

import { useRoute } from "vue-router";

import { useSettingsStore } from "@/store/settings";

const noSideBarRoutes = ["/login", "/signup", "/error"];

export default defineComponent({
  name: "App",
  components: {
    Private,
    Public,
    IonApp,
    Loader,
  },
  setup() {
    const route = useRoute();
    const { locale } = useI18n();
    const publicPage = computed(() => {
      return noSideBarRoutes.includes(route.path.toLowerCase());
    });
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle("dark", shouldAdd);
    }

    const settingsStore = useSettingsStore();
    const settings = computed(() => settingsStore.settings);
    /*   watchEffect(() => {
      if (settings.value) {
        document.body.classList.toggle("dark", settings.value.dark_mode);
      } else document.body.classList.toggle("dark", true);
    }); */
    return {
      publicPage,
    };
  },
});
</script>
<style>
.textColor-main {
  @apply text-font-color dark:text-font-color-inverted;
}
.textColor-inverted {
  @apply text-white;
}
.cardBackground {
  @apply bg-light-gray text-font-color;
}

.primaryColor {
  @apply bg-primary hover:bg-white hover:border border-primary hover:text-primary !important;
}
.textColor-primary {
  @apply text-primary;
}
.border-primary {
  @apply border-blue-500;
}
.buttonInactive-primary {
  @apply bg-transparent border-2 border-primary text-primary !important;
}
.buttonActive-primary {
  @apply primaryColor text-white cursor-pointer;
}

.successColor {
  @apply bg-green-600 hover:bg-green-700 focus:ring-green-500 !important;
}
.buttonActive-success {
  @apply successColor text-white;
}

.softColor {
  @apply bg-gray-200 hover:bg-gray-300 focus:ring-gray-400 !important;
}
.buttonActive-soft {
  @apply softColor text-gray-700;
}

.dangerColor {
  @apply bg-danger hover:bg-transparent hover:border border-danger hover:text-danger !important;
}
.buttonInactive-danger {
  @apply bg-red-50 text-red-700;
}
.buttonActive-danger {
  @apply dangerColor text-white cursor-pointer;
}
.litepie-datepicker {
  @apply mt-[55px] md:mt-0;
}
</style>
