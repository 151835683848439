import { defineStore } from "pinia";

import AuthService from "@/services/auth.service";
import router from "@/router";

import { useToast } from "vue-toastification";

import useBackendFetch from "@/repositories/BackendFetch";

import { useSettingsStore } from "@/store/settings";
import { useDiveStore } from "@/store/dive";
import { useTrainingStore } from "@/store/training";
import { useWearableStore } from "@/store/wearable";
import { useLogbookStore } from "@/store/logbook";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const UsersRepository = RepositoryFactory.get("users");

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore("user", {
  state: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const loggedIn = user ? true : false;
    return {
      user,
      loggedIn,
    };
  },
  actions: {
    async login(user) {
      try {
        this.resetUser();
        this.user = await AuthService.login(user);
        if (this.user.user) {
          const settingsStore = useSettingsStore();
          localStorage.setItem("user", JSON.stringify(this.user));
          await settingsStore.get();
          this.loggedIn = true;
          const toast = useToast();

          toast.success("You logged in successfully");
        } else this.resetUser();
      } catch (error) {
        this.resetUser();
      }
      return this.user;
    },
    async forgetPassword(user) {
      this.resetUser();
      const { data, error } = await AuthService.forgetPassword(user);
      const toast = useToast();

      toast.success("An email has been sent with the reset link");
      return data;
    },
    async resetPassword(user, token) {
      this.resetUser();
      const { data, error } = await useBackendFetch("/reset-password")
        .post({
          email: user.email,
          password: user.password,
          password_confirmation: user.confirmPassword,
          token: token,
        })
        .json();

      const toast = useToast();
      if (!error.value) {
        toast.success("Your password has been resetted");
        router.push("/login");
      } else toast.error("An error occurred");
      return { data, error };
    },
    async logout() {
      try {
        const response = await AuthService.logout();

        const logbookStore = useLogbookStore();
        const diveStore = useDiveStore();
        const wearableStore = useWearableStore();
        const trainingStore = useTrainingStore();
        this.loggedIn = false;
        logbookStore.$reset();
        diveStore.$reset();
        wearableStore.$reset();
        trainingStore.$reset();

        //window.location.href='/login';
        router.push("/");
        const toast = useToast();
        toast.success("You logged out successfully");

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    resetUser() {
      this.user = null;
      localStorage.removeItem("user");
      this.loggedIn = false;
    },
    async register(user) {
      const result = await useBackendFetch("signup").post(user).json();
      if (result.error !== null) {
        const toast = useToast();

        toast.success("You signed up successfully");
      } else this.resetUser();

      return result;
    },
    hasRole(role) {
      const u = this.user;
      let found = false;
      role.forEach(function (r) {
        if (u && u.user.roles) {
          if (
            u.user.roles.some((e) => e.slug.toLowerCase() === r.toLowerCase())
          )
            found = true;
        }
      });
      return found;
    },
    hasActiveSubscription() {
      const u = this.user;
      if (u && (u.user.subscription_type || this.isAbleTo("admin_permission")))
        return true;
      return false;
    },
    hasSubscriptions(subscriptions) {
      if (!subscriptions) return true;
      if (this.isAbleTo("admin_permission")) return true;
      const u = this.user;
      if (!u || !u.user) return false;
      if (Array.isArray(subscriptions)) {
        let found = false;

        subscriptions.every(function (a) {
          if (u && u.user.subscription_type) {
            found = u.user.subscription_type.includes(a);

            return false;
          }
        });
        return found;
      } else if (this.user.user.subscription_type) {
        return this.user.user.subscription_type.includes(subscriptions);
      }
      return false;
    },
    isAbleTo(ability) {
      if (!ability) return true;
      const u = this.user;
      if (!u || !u.user) return true;
      if (Array.isArray(ability)) {
        let found = false;
        ability.forEach(function (a) {
          if (u && u.user.session_permissions) {
            if (
              u.user.session_permissions.some(
                (e) => e.toLowerCase() === a.toLowerCase()
              )
            )
              found = true;
          }
        });
        return found;
      } else if (this.user.user.session_permissions)
        return this.user.user.session_permissions.includes(ability);
      return false;
    },
    async updateActiveSubscription(subscription) {
      if (this.user) {
        this.user.active_subscription = subscription;
        await this.getMe();
      }
    },
    async setDanId(values) {
      try {
        return await UsersRepository.setDanId(values);
      } catch (error) {
        return error.response;
      }
    },
    async setCoupon(coupon) {
      try {
        return await UsersRepository.setCoupon(coupon);
      } catch (error) {
        return error.response;
      }
    },
    async getMe() {
      try {
        const { data } = await UsersRepository.getMe();
        this.user.user = data;
        localStorage.setItem("user", JSON.stringify(this.user));
      } catch (error) {
        return error.response;
      }
    },
    async updateGenInfo(values, userId) {
      try {
        const { data } = await UsersRepository.updateGenInfo(values, userId);
        this.user.user = data;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.loggedIn = true;
      } catch (error) {
        this.resetUser();
      }
    },
  },
});
