import { onMounted, onBeforeUnmount, nextTick } from "vue";
import { useSettingsStore } from "@/store/settings";
import { useRoute } from "vue-router";

// by convention, composable function names start with "use"
export function useWindowSize() {
  const route = useRoute();
  const settingsStore = useSettingsStore();
  onMounted(() => {
    nextTick(() => {
      window.addEventListener("resize", onResize);
    });
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
  });
  const onResize = () => {
    settingsStore.checkPlaformWidth(route);
  };
}
