
import { IonContent, IonMenu, IonMenuToggle, menuController } from "@ionic/vue";
import { defineComponent, computed } from "vue";

import { useUserStore } from "@/store/user";

import { useI18n } from "vue-i18n";

import { HomeIcon, UserIcon, BookOpenIcon } from "@heroicons/vue/outline";
import MenuItem from "./MenuItem.vue";

import { useDiveStore } from "@/store/dive";
import { useTrainingStore } from "@/store/training";
import { useWearableStore } from "@/store/wearable";
import { useLogbookStore } from "@/store/logbook";

export default defineComponent({
  name: "SideMenu",
  components: {
    IonContent,

    IonMenu,
    IonMenuToggle,
    MenuItem,
  },
  props: ["splitPane"],
  setup() {
    const { t } = useI18n({});
    const userStore = useUserStore();
    const logbookStore = useLogbookStore();
    const diveStore = useDiveStore();
    const wearableStore = useWearableStore();
    const trainingStore = useTrainingStore();
    const user = computed(() => (userStore.user ? userStore.user.user : null));

    const navigation = [
      {
        name: "menu.Dashboard",
        icon: "house",
        fa: true,
        href: "/dashboard",
        requiresActiveSubscription: true,
        current: false,
      },
      {
        name: "menu.PlansAndSubscriptions",
        icon: "credit-card",
        fa: true,
        href: "/subscriptions",
        current: true,
      },
      {
        name: "menu.Profile",
        icon: "user",
        fa: true,
        href: "/profile",
        current: false,
      },
      /* {
        name: "menu.Wearable",
        icon: "microchip",
        fa: true,
        href: "/wearable",
        current: false,
        requiresActiveSubscription: true,
        requiredSubscriptions: ["advanced"],
      }, */
      {
        name: "menu.Live",
        icon: "desktop",
        fa: true,
        href: "/wearable/live",
        current: false,
        requiresActiveSubscription: true,
        requiredSubscriptions: ["advanced"],
      },
      {
        name: "menu.Logbook",
        icon: "book-open",
        fa: true,
        href: "/logbook",
        current: false,
        requiresActiveSubscription: true,
      },
      {
        name: "menu.Statistics",
        icon: "chart-line",
        fa: true,
        href: "/statistics",
        current: false,
        requiresActiveSubscription: true,
      },
      /* {
        name: "menu.Fitness",
        icon: "person-walking",
        fa: true,
        href: "/training",
        current: false,
        requiresActiveSubscription: true,
        requiredSubscriptions: ["advanced", "fitness"],
      }, */
      {
        name: "menu.Roles",
        fa: true,
        icon: "user-tag",
        href: "/roles/users",
        current: false,
        permission: "edit_users_roles",
      },
      {
        name: "menu.Divers",
        fa: true,
        icon: "user-group",
        href: "/divers",
        current: false,
        permission: "view_all_users",
      },
      {
        name: "menu.EvaluateDoppler",
        fa: true,
        icon: "user-group",
        href: "/doppler/evaluate",
        current: false,
        permission: "evaluate-doppler",
      },
      /*{
          name: 'menu.YourDivers',
          icon: BookOpenIcon,
          href: '/operator/users',
          permission:'own_other_users',
          current: false,
        },
        */
    ];

    const handleLogout = async () => {
      const { data, error } = await userStore.logout();
      menuController.close();
    };

    const showItem = (item) => {
      if (item.permission && !userStore.isAbleTo(item.permission)) return false;
      if (item.requiresActiveSubscription && !userStore.hasActiveSubscription())
        return false;
      if (
        item.requiredSubscriptions &&
        !userStore.hasSubscriptions(item.requiredSubscriptions)
      )
        return false;

      return true;
    };

    return {
      navigation,
      handleLogout,
      user,
      userStore,
      t,
      showItem,
    };
  },
});
