<template>
  <div
    v-if="isLoading"
    class="z-50 flex h-screen text-center bg-gray-900"
  >
    <div class="flex flex-col m-auto">
      <img src="/assets/icon/spinner.svg" />
      <span v-if="message" class="text-white">{{ t("loader."+message) }} <span v-if="diveN">#{{diveN}}</span> <span v-if="progress">...{{ progress }}%</span></span>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, watch, ref } from "vue";
import { storeToRefs } from "pinia";

import { useLoaderStore } from "@/store/loader";
import { useUserStore } from "@/store/user";

import {useI18n} from "vue-i18n";


export default defineComponent({
  name: "LoaderComponent",

  setup() {
    const {t}=useI18n({})
    const loaderStore = useLoaderStore();
    const userStore = useUserStore();
    const message = ref();
    const progress = ref();
    const diveN = ref();
    const { isLoading } = storeToRefs(loaderStore);
    const userId = computed(() => { 
      if (userStore.user)
        return userStore.user.user.id;
      return null;  
      });

    const listenToWs = () => {
      if (userStore.user && userStore.user.token && 1==0) {
        window["Echo"].connector.options.auth.headers.Authorization =
          "Bearer " + userStore.user.token;
        window["Echo"]
          .private("progress." + userStore.user.user.id)
          .listenToAll((event, data) => {
            message.value = data.text;
            progress.value = data.perc;
            diveN.value=data.dive_n;
          });
      }
    };
    listenToWs();
    watch(userId, listenToWs);

    return {
      isLoading,
      message,
      progress,
      diveN,
      t,
    };
  },
});
</script>
