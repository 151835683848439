import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b24474e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-2 text-lg font-semibold textColor-main dark:text-font-color-inverted text-center" }
const _hoisted_2 = { class: "textColor-main dark:text-font-color-inverte" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_BaseBanner = _resolveComponent("BaseBanner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "light" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, {
                slot: "start",
                class: "textColor-main"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { "auto-hide": false }),
                  (_ctx.showBack)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        "default-href": _ctx.defaultUrl
                      }, null, 8, ["default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (true == false)
              ? (_openBlock(), _createBlock(_component_BaseBanner, {
                  key: 0,
                  class: "buttonActive-danger"
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}