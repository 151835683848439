import { defineStore } from "pinia";

import dayjs from "dayjs";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const DivesRepository = RepositoryFactory.get("dives");
import i18n from "../i18n";

const { t } = i18n.global;
// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useLogbookStore = defineStore("logbook", {
  state: () => {
    const dives = [];
    const page = 1;
    const pagination = null;
    const user = null;
    const sortOptions = [
      { name: t("common.Date"), field: "date", asc: false, current: true },
      { name: t("dive.Depth"), field: "depth", asc: true, current: false },
      {
        name: t("dive.Runtime"),
        field: "runtime",
        asc: true,
        current: false,
      },
      { name: t("dive.DGRA"), field: "gf->value", asc: true, current: false },
    ];

    const filters = [
      {
        id: "type",
        name: t("dive.DiveType"),
        options: [
          { value: "oc", label: t("dive.OpenCircuit"), checked: false },
          { value: "reb", label: t("dive.Reb"), checked: false },
          { value: "apnea", label: t("dive.Apnea"), checked: false },
        ],
      },
      {
        id: "dcs",
        name: t("dive.DCS"),
        options: [
          { value: "true", label: t("dive.DCS"), checked: false },
          { value: "false", label: t("dive.NoDCS"), checked: false },
        ],
      },
    ];

    const dateValue = {
      startDate: "",
      endDate: "",
    };
    return {
      dives,
      sortOptions,
      dateValue,
      filters,
      page,
      user,
    };
  },
  actions: {
    async loadDives(refreshData, userId) {
      const dateSearch = this.dateValue;

      if (dateSearch.startDate) {
        dateSearch.startDate = dayjs(dateSearch.startDate).unix();
        dateSearch.endDate = dayjs(dateSearch.endDate).unix();
      }
      const f = [];
      let s;
      for (let i = 0; i < this.filters.length; i++) {
        for (let oi = 0; oi < this.filters[i].options.length; oi++) {
          if (this.filters[i].options[oi].checked)
            f.push({
              field: this.filters[i].id,
              value: this.filters[i].options[oi].value,
            });
        }
      }

      for (let i = 0; i < this.sortOptions.length; i++) {
        if (this.sortOptions[i].current) {
          s = {
            field: this.sortOptions[i].field,
            direction: this.sortOptions[i].asc ? "ASC" : "DESC",
          };
        }
      }
      try {
        await DivesRepository.getByUser(
          userId,
          this.page,
          s.field,
          s.direction,
          f,
          dateSearch
        ).then(({ data }) => {
          if (refreshData) this.dives = data.dives;
          else this.dives = this.dives.concat(data.dives);
          this.pagination = data.pagination;
          this.user = data.diver;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async sortChanged(options, user) {
      this.sortOptions = options;
      this.page = 1;
      await this.loadDives(true, user);
    },
    async filterChange(f, user) {
      this.filters = f;
      this.page = 1;
      await this.loadDives(true, user);
    },
    async datesChange(dates, user) {
      this.dateValue = dates;
      this.page = 1;
      await this.loadDives(true, user);
    },
    nextPage() {
      this.page++;
    },
  },

  getters: {
    // automatically infers the return type as a number
  },
});
